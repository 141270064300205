import { customerOktaConfig, merchantOktaConfig } from "../../config";
import { MODE } from "../constants";
import { getAuthMode } from "./storage";

/**
 * Returns Okta config by mode
 * @param {string} mode - Okta mode (CUSTOMER|MERCHANT|DEFAULT). Mode auto fetched from local storage when empty
 */
const getOktaConfig = (mode = "") => {
  if (mode === "") {
    mode = getAuthMode();
  }

  return mode === MODE.CUSTOMER ? customerOktaConfig : merchantOktaConfig;
};

export default getOktaConfig;