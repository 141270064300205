import React from "react";
import { Message } from "../../../components";

export const AccountFetchError = () => (
  <Message
    severity="warning-or-significant"
    bg="secondary.pink.t30"
    title="Oops, there was a problem retrieving your account information"
  >
    Please return to cart and select an alternative payment method.
  </Message>
);
