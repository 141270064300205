// App Message area
export const APP_MESSAGE_AREA = {
  CHECKOUT: "checkout",
  PARTNER_HUB: "partner-hub",
};

export const APP_MESSAGE_TYPE = {
  NOTIFICATION: "notification",
  ERROR: "error",
};

export const APP_MESSAGE_BG = {
  [APP_MESSAGE_TYPE.NOTIFICATION]: "secondary.lightBlue.t25",
  [APP_MESSAGE_TYPE.ERROR]: "secondary.pink.t30",
};
