import React from "react";
import { Container, Text } from "basis";

import { Accounts } from "./Accounts";

export const PaymentMethod = ({
  disabled = false,
  accounts,
  onChange,
  label = "Using account:"
}) => {
  return (
    <>
      <Container>
        <Text textStyle="heading5" margin="8 0 2">
          Your account
        </Text>

        <Text color="grey.t75" margin="0 0 4">
          {label}
        </Text>
      </Container>

      {accounts?.length > 0 && (
        <Container margin="0" border>
          <Accounts
            disabled={disabled}
            accounts={accounts}
            onChange={onChange}
          />
        </Container>
      )}
    </>
  );
};
