import React from "react";
import { Message } from "../../../components";

export const InvalidLinkRequest = () => (
  <Message
    severity="warning-or-significant"
    bg="secondary.pink.t30"
    title="Invalid request"
  >
    Your request does not look correct. Please try again.
  </Message>
);
