import React from "react";
import { Text } from "basis";

import { Flex, Pill } from "../../../components";

const MAX_DISPLAY_POINTS = 999999;

export const PointsPurchaseHeader = ({ amount = 0, burnRate = 0 }) => {
  const amountAsPoints = Math.round(amount * burnRate);
  const points =
    amountAsPoints < MAX_DISPLAY_POINTS
      ? amountAsPoints
      : `${MAX_DISPLAY_POINTS}+`;

  return (
    <Flex alignContent="center">
      <Text>
        <strong>Your purchase</strong>
      </Text>

      <Flex alignContent="center" gap="6px">
        <Text>${amount}</Text>

        {amountAsPoints > 0 && (
          <Pill compact grey>
            or {points} pts
          </Pill>
        )}
      </Flex>
    </Flex>
  );
};
