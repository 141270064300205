import { useEffect, useState } from "react";
import { useLocation } from "@reach/router";
import { navigate } from "gatsby";

import { merchantOktaConfig } from "../../config";

import { ALLOWED_COUNTRIES, MERCHANT_PAGE_ROUTES } from "../../constants";
import { MODE } from "../constants";
import useSecurity from "./useSecurity";
import resetAuth from "../utils/resetAuth";
import { isJWTValid } from "../utils/isJWTValid";
import {
  changeMerchantProfile,
  getLinkedProfiles,
  getAuthMode,
  getMerchantProfile,
  getToken,
  setAuthMode,
  saveLinkedProfiles,
} from "../utils/storage";
import fetchLinkedProfiles from "../profile/fetchLinkedProfiles";

let _fetchingProfile = false;

export default function useMerchantAuth() {
  const [activeProfile, setActiveProfile] = useState(null);
  const [linkedProfiles, setLinkedProfiles] = useState([]);

  const { pathname } = useLocation();
  const { loading, error, result } = useSecurity(merchantOktaConfig);
  const { authState, oktaAuth } = result;

  const isLoggedIn = () => {
    if (getAuthMode() !== MODE.MERCHANT) {
      return false;
    }

    const token = getToken();
    const isValid = isJWTValid(token);

    return isValid;
  };

  const start = () => {
    resetAuth();
    setAuthMode(MODE.MERCHANT);
  };

  const logout = () => {
    resetAuth();

    oktaAuth.signOut({
      clearTokensBeforeRedirect: true,
      postLogoutRedirectUri: MERCHANT_PAGE_ROUTES.Login,
    });
  };

  const updateProfile = () => {
    const merchantProfile = getMerchantProfile();
    const linkedProfiles = getLinkedProfiles();

    if (
      !merchantProfile?.country ||
      !merchantProfile?.currency ||
      !ALLOWED_COUNTRIES.includes(merchantProfile?.country)
    ) {
      return;
    }

    setActiveProfile(merchantProfile);
    setLinkedProfiles(linkedProfiles);
  };

  const fetchProfile = async () => {
    _fetchingProfile = true;
    const { success, profiles } = await fetchLinkedProfiles();
    _fetchingProfile = false;

    // TODO: handle profile fetch errors
    if (!success) {
      logout();
      return;
    }

    // save linked profile
    saveLinkedProfiles(profiles);

    // update merchant profile in state
    updateProfile();
  };

  useEffect(() => {
    oktaAuth.start();
    updateProfile();
  }, []);

  useEffect(() => {
    async function checkProfiles() {
      // check and fetch profiles if user is authenticated and profiles are not already fetched
      if (
        pathname.includes(MERCHANT_PAGE_ROUTES.Login) &&
        isLoggedIn() &&
        !_fetchingProfile &&
        !activeProfile
      ) {
        await fetchProfile();
        navigate(MERCHANT_PAGE_ROUTES.Default);
      }
    }

    checkProfiles();
  }, [authState]);

  return {
    getToken: getToken,
    loading: loading || _fetchingProfile,
    error,
    profile: activeProfile,
    linkedProfiles,
    authState,
    oktaAuth,
    isLoggedIn,
    start,
    changeProfile: changeMerchantProfile,
    fetchProfile: fetchProfile,
    logout,
  };
}
