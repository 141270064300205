import React from "react";
import styled from "styled-components";
import { Message } from "basis";

import { LOGIN_ERROR_TYPE } from "../../../core/auth";

const Wrapper = styled.div`
  margin: 24px 0;

  button {
    cursor: pointer;
    width: 100%;
    font-size: 15px;
    font-weight: 400;
  }
`;

export const LoginError = ({ errorCode, additionalDesc, children }) => {
  let title,
    desc = "";

  switch (errorCode) {
    case LOGIN_ERROR_TYPE.NO_ALLOWED_FACTORS:
      title = "Login not allowed";
      desc = `Unfortunately, your account is not linked to a mobile number.`;
      break;

    default:
      title = "Login failed";
      desc = `Oops! Something went wrong while signing you in securely.`;
      break;
  }

  return (
    <>
      <Message
        severity="warning-or-significant"
        bg="secondary.pink.t30"
        title={title}
      >
        {desc} {additionalDesc}
      </Message>
      <Wrapper>{children}</Wrapper>
    </>
  );
};
