import React from "react";
import { Message } from "../../../components";

export const MerchantSettingsError = () => (
  <Message
    severity="warning-or-significant"
    bg="secondary.pink.t30"
    title="Invalid request"
  >
    Sorry, merchant setup does not look correct. Please try again.
  </Message>
);
