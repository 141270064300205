export const ERROR_CODES = {
  NO_ELIGIBLE_ACCOUNTS: "no-eligible-accounts",
  ACCOUNT_FETCH_ERROR: "account-fetch-failed",
  INSUFFICIENT_POINTS: "insufficient-points",
};

export const BUTTON_TEXT = {
  MANUAL_PAYMENT: "Pay with credit card / account number",
};

export const ROUTES = {
  MANUAL_PAYMENT: "/checkout",
  WALLET_PAYMENT: "/wallet/checkout",
  POINTS_PAYMENT: "/points/checkout",
  REWARDS_LINK: "/rewards/link",
  TOKEN_CONSENT : "/token/checkout"
};
