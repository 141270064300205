import styled from "styled-components";
import { BREAKPOINT, COLOR } from "../../components/theme";

const MAX_CONTENT_WIDTH = "350px";

export const CustomerOktaContainer = styled.div`
  margin: 0 auto;

  #okta-sign-in {
    position: relative !important;
    border: none !important;
    margin: 0 !important;
  }

  #okta-sign-in.auth-container.main-container {
    box-shadow: none !important;
  }

  #okta-sign-in .okta-sign-in-header {
    visibility: hidden;
  }

  #okta-sign-in .o-form-head {
    display: none !important;
  }

  #okta-sign-in .auth-content {
    padding: 0 !important;
    width: ${MAX_CONTENT_WIDTH} !important;
  }

  #okta-sign-in .auth-header {
    padding: 10px;
  }

  #okta-sign-in .mfa-verify {
    visibility: hidden;
  }

  @media only screen and (max-width: ${BREAKPOINT.SIZE_MOBILE}) {
    #okta-sign-in .auth-content {
      max-width: 100% !important;
    }
  }

  #okta-sign-in .auth-footer {
    display: none !important;
  }

  #okta-sign-in.main-container {
    background-color: transparent !important;
  }

  #okta-sign-in.auth-container .btn-disabled {
    opacity: 0.45;
    cursor: not-allowed;
  }

  #okta-sign-in.auth-container input[type="text"],
  #okta-sign-in.auth-container input[type="password"] {
    background-color: rgb(242, 242, 242) !important;
    border-width: 0px !important;
    border: none !important;
    outline: 0 !important;
  }

  #okta-sign-in.auth-container input[type="text"]:hover,
  #okta-sign-in.auth-container input[type="password"]:hover {
    outline: none !important;
  }

  #okta-sign-in.auth-container input[type="text"]:focus,
  #okta-sign-in.auth-container input[type="password"]:focus {
    outline: none !important;
  }

  #okta-sign-in.auth-container
    .mfa-verify-passcode
    .okta-form-subtitle.o-form-explain::before {
    content: "Please enter the verification code that's been sent to your mobile number ";
  }

  #okta-sign-in.auth-container
    .mfa-verify-passcode
    .okta-form-subtitle.o-form-explain::after {
    content: ". Code expires in 15 minutes.";
  }

  #okta-sign-in.auth-container
    .button.sms-request-button[data-se="sms-send-code"] {
    background: ${COLOR.GREEN};
    color: ${COLOR.WHITE};
  }

  #okta-sign-in.auth-container .button-primary:hover:not(.btn-disabled) {
    background: rgb(0, 70, 170) !important;
    font-size: 18px;
    line-height: 28px;
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
  }
   #okta-sign-in.auth-container .button-primary {
    font-size: 18px;
    line-height: 28px;
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
  }

  #okta-sign-in .btn-secondary:not(.btn-disabled) {
    background-color: ${COLOR.WHITE} !important;
    color: rgb(0, 106, 255) !important;
    border: 1px solid rgb(0, 106, 255) !important;
  }

  #okta-sign-in .btn-secondary:hover:not(.btn-disabled) {
    background: rgb(0, 106, 255) !important;
    color: rgb(255, 255, 255) !important;
  }

  #okta-sign-in .auth-divider {
    background-color: transparent !important;
  }

  #okta-sign-in .auth-divider .auth-divider-text {
    text-transform: lowercase;
    font-weight: 600;
    color: ${COLOR.BLACK};
  }
`;
