import axios from "axios";
import { appUrl } from "../config";
import validateBannerConfig from "./validate";
import { logger } from "../logger";

export default class Banner {
  async getConfig() {
    try {
      const response = await axios.get(
        `${appUrl}/settings/banner/banner.json`
      );

      if (response.status !== 200) {
        throw new Error(
            'Failed to fetch banner settings'
        );
      }

      validateBannerConfig(response.data);

      return response.data;
    } catch (err) {
      logger.error(`Failed to fetch banner settings - ${err}`);
      return {};
    }
  }
}
