import React, { useState } from "react";
import { Text, Grid } from "basis";
import {
  Container,
  FormattedPrice,
  Input,
  Label,
  RangeSlider,
} from "../../../components";
import styled from "styled-components";

const InputContainer = styled.div`
  min-height:125px;
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }
`;

const RowContainer = styled.div`
  inline-size: 100px;
  overflow-wrap: break-word;
  font-size: 15px;
  display: flex;
  flex-direction: row;
  width: 100%;
`;

export const PointSelection = ({
  disabled = false,
  min,
  max,
  value,
  remainingAmount,
  onChange = () => {},
  validate = () => {},
}) => {
  const [enteredPoints, setEnteredPoints] = useState(value);

  const handleOnChange = ({ value }) => {
    setEnteredPoints(value);
    let val = Number(value);
    onChange(val);
  };

  const handleOnBlur = (e) => {
    let val = Number(e.target.value);
    let isValid = onChange(val);
    if (isValid) {
      setEnteredPoints(val);
    }
  };
  const handleSilder = (value) => {
    onChange(value);
    setEnteredPoints(value);
  };

  return (
    <>
      <Container divider>
        <Text textStyle="heading5" margin="8 0 2">
          Pay with points
        </Text>

        <Text color="grey.t75" margin="0 0 4">
          A minimum of {min} pts are required to continue
        </Text>
      </Container>

      <Container margin="4 0 0">
        <Grid preset="page" rowsGap={8}>
          <Grid.Item colSpan="all">
            <RowContainer placeItems="center">
              <InputContainer>
                <Input
                  type="number"
                  label="Points"
                  value={enteredPoints}
                  disabled={disabled}
                  validate={validate}
                  onChange={handleOnChange}
                  onBlur={handleOnBlur}
                />
              </InputContainer>
              <Text
                color="highlight.blue.t100"
                textStyle="heading3"
                margin="12 5 2 5"
              >
                +
              </Text>
              <Label title="Pay" padding="14px 0px 0px 0px">
                <FormattedPrice price={remainingAmount} />
              </Label>
            </RowContainer>
          </Grid.Item>
        </Grid>
      </Container>

      <RangeSlider
        min={min}
        max={max}
        step={50}
        value={value}
        disabled={disabled}
        onChange={handleSilder}
      />
    </>
  );
};
