import React from "react";
import { RadioGroup } from "../../../components";

import { AccountItem } from "./AccountItem";

export const Accounts = ({
  disabled = false,
  accounts = [],
  onChange = () => {},
}) => {
  const handleAccountChange = (value) => {
    // TODO: implement disabled account check here
    const matchingAccount = accounts.find(
      ({ accountID }) => accountID === value
    );

    onChange(matchingAccount);
  };

  const radioOptions = accounts.map((acc) => ({
    ...acc,
    label: acc?.productName,
    value: acc?.accountID,
  }));

  const renderAccountItem = ({
    productCode,
    productName,
    availableBalance,
    points,
  }) => (
    <AccountItem
      productName={productName}
      productCode={productCode}
      availableBalance={availableBalance}
      points={points}
    />
  );

  return (
    <RadioGroup
      options={radioOptions}
      renderItem={renderAccountItem}
      onChange={handleAccountChange}
      isDisabled={disabled}
      selectFirstOption
    />
  );
};
