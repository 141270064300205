import axiosHelper from "../../../core/axios-helper";
import { ENDPOINTS } from "../../../services/checkout";

export const completeConsent = async (transactionId, accountId, sessionId) => {
  if (!transactionId) {
    throw new Error(
      "completePayment(): invalid request. transactionID is mandatory"
    );
  }
  const response = await axiosHelper.withCustomerAuth.post(
    ENDPOINTS.COMPLETE_CONSENT,
    JSON.stringify({
      transactionId,
      accountId,
      sessionId,
    })
  );
  if (!response?.data?.redirectUrl) {
    throw new Error(
      "completePayment(): invalid response. response.url mandatory",
      { response }
    );
  }
  return response?.data;
};
