import { ENDPOINTS } from "../../../services/checkout";
import axiosHelper from "../../axios-helper";
import { ERROR_TYPES } from "../constants";

const fetchMerchantProfileById = async (merchantId) => {
  try {
    const url = `${ENDPOINTS.MERCHANT_GET_PROFILE}?merchantId=${merchantId}`;
    const response = await axiosHelper.withMerchantAuth.get(url);

    return { success: true, profile: response?.data };
  } catch (ex) {
    const error = {
      type: ERROR_TYPES.PROFILE_FETCH_FAILED,
      message: ex.message,
    };

    return { success: false, error };
  }
};

export default fetchMerchantProfileById;
