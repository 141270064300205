import React from "react";
import styled from "styled-components";

import { Footer, Container, TestMode, Flex, Logo } from "../../components";
import { COLOR } from "../../components/theme";
import { envConfig } from "../../core/config";
import { ENVIRONMENT } from "../../core/constants";
import { AppMessage, APP_MESSAGE_AREA } from "../shared";

const StyledWrapper = styled.div`
  display: grid;
  grid-template-areas:
    "header"
    "content"
    "footer";

  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr auto;
  height: 100vh;
  background-color: ${COLOR.LIGHT_GREY};
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' viewBox='0 0 907.087 907.087'%3E%3Cdefs%3E%3ClinearGradient id='linear-gradient' x1='0.5' y1='0.006' x2='0.5' y2='1.993' gradientUnits='objectBoundingBox'%3E%3Cstop offset='0' stop-color='%23f2eeea'/%3E%3Cstop offset='0.537' stop-color='%23fff'/%3E%3C/linearGradient%3E%3CclipPath id='clip-path'%3E%3Crect id='Rectangle_2' data-name='Rectangle 2' width='907.087' height='907.087' transform='translate(0 3796.621)' fill='url(%23linear-gradient)'/%3E%3C/clipPath%3E%3ClinearGradient id='linear-gradient-3' x1='0.196' y1='0.5' x2='-0.298' y2='0.5' xlink:href='%23linear-gradient'/%3E%3ClinearGradient id='linear-gradient-4' x1='0.237' y1='0.5' x2='0.503' y2='0.5' xlink:href='%23linear-gradient'/%3E%3C/defs%3E%3Cg id='Group_2' data-name='Group 2' transform='translate(0 -3796.621)'%3E%3Crect id='Rectangle_1' data-name='Rectangle 1' width='907.087' height='907.087' transform='translate(0 3796.621)' fill='url(%23linear-gradient)'/%3E%3Cg id='Group_1' data-name='Group 1' clip-path='url(%23clip-path)'%3E%3Cpath id='Path_1' data-name='Path 1' d='M1921.905 2783.693l-272.5-190.805L559.022 4150.119 2116.252 5240.5 2307.057 4968 1022.326 4068.426Z' fill='url(%23linear-gradient-3)'/%3E%3Cpath id='Path_2' data-name='Path 2' d='M1873.11 1690.318l-538.47-377.041L-820.018 4390.447 2257.15 6545.1l377.041-538.47L95.494 4229.018Z' fill='url(%23linear-gradient-4)'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
  background-size: cover;
  height: auto !important;
  min-height: 100vh;

  li::before {
    background-color: ${COLOR.MID_GREY};
    width: 0.3em;
    height: 0.3em;
  }
`;

const StyledHeader = styled.header`
  grid-area: header;
`;

const StyledMain = styled.main`
  grid-area: content;
`;

const StyledFooter = styled.footer`
  grid-area: footer;
`;

const StyledMerchantLogo = styled.img`
  height: 30px;
  max-width: 220px;
`;

const Scene = ({ merchantLogoURL, country, theme, children }) => (
  <StyledWrapper>
    <StyledHeader>
      <Container
        bg={theme?.bgColor ?? "white"}
        color={theme?.fontColor}
        fullWidth
        shadow
      >
        <Container maxWidth="1080" gutters>
          <Flex padding="15px 0">
            {/* only show header when logo url is defined */}
            {Boolean(merchantLogoURL) && (
              <Header
                merchantLogoURL={merchantLogoURL}
                variant={theme?.variant}
              />
            )}
          </Flex>
        </Container>
      </Container>
    </StyledHeader>

    <StyledMain>
      <AppMessage area={APP_MESSAGE_AREA.CHECKOUT} />

      {envConfig !== ENVIRONMENT.PROD && <TestMode />}

      {children}
    </StyledMain>

    {/* only show footer when logo url is defined */}
    {Boolean(merchantLogoURL) && (
      <StyledFooter>
        <Footer
          bg={theme?.bgColor ?? "white"}
          fontColor={theme?.fontColor}
          variant={theme?.variant}
          country={country}
        />
      </StyledFooter>
    )}
  </StyledWrapper>
);

const Header = ({ merchantLogoURL, variant }) => {
  if (merchantLogoURL) {
    return (
      <>
        <StyledMerchantLogo src={merchantLogoURL} alt="merchant-logo" />
        <Logo mini variant={variant} />
      </>
    );
  }

  return null;
};

export default Scene;
