import jwtDecode from "jwt-decode";

export const isJWTValid = (token) => {
  if (!Boolean(token)) {
    return false;
  }

  const { exp = 0 } = jwtDecode(token);

  if (exp <= 0) {
    return false;
  }

  const expiryTime = new Date(exp * 1000);
  const now = new Date();

  if (expiryTime < now) {
    return false;
  }

  return true;
};
