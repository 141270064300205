import React from "react";
import { Text } from "basis";
import { Container, Flex, FormattedPrice } from "../../../components";
import { SelectedPaymentMethod } from "./SelectedPaymentMethod";

export const ConfirmPayment = ({
  amount,
  selectedAccount,
  selectedPoints = 0,
  remainingAmount = 0,
}) => (
  <>
    <Container divider>
      <Text textStyle="heading5" margin="8 0 2">
        Confirm payment
      </Text>

      <Text margin="6 0 4">
        <strong>Payment method</strong>
      </Text>

      <SelectedPaymentMethod selectedAccount={selectedAccount} />
      
      <Text margin="6 0 0">
        <strong>Summary</strong>
      </Text>

      <Flex padding="8px 0 0">
        <Text color="grey.t75">Your purchase</Text>
        <FormattedPrice price={amount} align="right" />
      </Flex>

      <Flex padding="8px 0 0">
        <Text color="grey.t75">Points</Text>
        <Text color="grey.t75">{parseInt(selectedPoints, 10)} pts</Text>
      </Flex>

      <Flex padding="8px 0 12px">
        <Text color="grey.t75">Plus pay</Text>
        <FormattedPrice price={remainingAmount} align="right" />
      </Flex>
    </Container>

    <Container margin="1 0 0">
      <Flex padding="12px 0 0">
        <Text color="grey.t75">
          <strong>Remainder to pay</strong>
        </Text>
        <FormattedPrice price={remainingAmount} align="right" />
      </Flex>
    </Container>
  </>
);
