export const CARD_LOGO_SRC_MAP = {
  // AU
  CCAUGOM: { logo: "go.png" },
  CCAUGOB: { logo: "go.png" },
  CCAUGEM: { logo: "gem.png" },
  CCAUBE: { logo: "BuyersEdge.png" },
  CCAUGAFS: { logo: "Creditline.png" },
  CCAUCLN: { logo: "Creditline.png" },
  // NZ
  CCNZOCV: { logo: "GemNZ.png" },
  CCNZGEM: { logo: "GemNZ.png" },
  CCNZCLN: { logo: "CreditlineNZ.png" },
};

export const CARD_LOGO_ICON_MAP = {
  CCAUDJI: { icon: "davidJonesIcon" },
  CCAUDJG: { icon: "davidJonesPrestige" },
  CCAUDJM: { icon: "davidJonesPremiere" },
  EMPTY: { icon: "emptyCreditCard" },
};
export const TOKEN_APPLY_SECTION = {
  APPLY_HEADER: "New To Latitude?",
  APPLY_DESCRIPTION: "Available on participating Latitude credit cards only. If you don't already have a Latitude credit card, you can apply online in as little as 10 minutes.",
  APPLY_BUTTON_TEXT: "Apply Online",
  APPLY_CARD_DESCRIPTION: `Complete your 10-15 minute online application at the Latitude Gem Visa site. Provide your income and employment details as well as your household expenses. You’ll receive a response in under 60 seconds!`,
  APPLY_APPROVED_HEADER: "Once approved you can resume your purchase by logging in, otherwise return to cart to review other options.",
  APPLY_BUTTON : 'Apply'
}
export const  APPLE_CONSENT_DATA = [
  {
    "cells": [
      { "value": "Products selected", "rowSpan": 1, "colSpan": 1, "isHeader": true },
      { "value": "Upgrade+ component", "rowSpan": 1, "colSpan": 1, "isHeader": true },
      { "value": "Latitude transactions #", "rowSpan": 1, "colSpan": 1, "isHeader": true },
      { "value": "Latitude Interest Free Payment Plans*", "rowSpan": 1, "colSpan": 1, "isHeader": true }
    ]
  },
  {
    "cells": [
      {
        "value": `Each iPhone, or Watch`, "rowSpan": 2, "colSpan": 1
      },
      { "value": `Monthly Payments,\nless Keep-the-Device Amount`, "rowSpan": 1, "colSpan": 1 },
      { "value": `First transaction`, "rowSpan": 1, "colSpan": 1 },
      { "value": "24 Months Interest Free with Equal Monthly Payments.​\nEqual monthly payments required.​", "rowSpan": 1, "colSpan": 1 }
    ]
  },
  {
    "cells": [
      { "value": "Keep-the Device Amount​", "rowSpan": 1, "colSpan": 1 },
      { "value": `Second transaction`, "rowSpan": 1, "colSpan": 1 },
      { "value": "25 Months Interest Free Payment Deferred.​\nPay anytime before the end of the plan.\nSee Upgrade+ portal for upgrade options.​", "rowSpan": 1, "colSpan": 1 }
    ]
  },
  {
    "cells": [
      { "value": `Each Mac or iPad​`, "rowSpan": 2, "colSpan": 1 },
      { "value": `Monthly Payments,\nless Keep-the-Device Amount​`, "rowSpan": 1, "colSpan": 1 },
      { "value": `First transaction`, "rowSpan": 1, "colSpan": 1 },
      { "value": `36 Months Interest Free with Equal Monthly Payments.​\nEqual monthly payments required.​`, "rowSpan": 1, "colSpan": 1 }
    ]
  },
  {
    "cells": [
      { "value": "Keep-the Device Amount​", "rowSpan": 1, "colSpan": 1 },
      { "value": `Second transaction`, "rowSpan": 1, "colSpan": 1 },
      { "value": `37 Months Interest Free Payment Deferred.​\nPay anytime before the end of the plan.\nSee Upgrade+ portal for upgrade options.​`, "rowSpan": 1, "colSpan": 1 }
    ]
  },
  {
    "cells": [
      { "value": `Apple One Family​ (Premium plan only)​`, "rowSpan": 1, "colSpan": 1 },
      { "value": "Monthly Payments​", "rowSpan": 1, "colSpan": 1 },
      { "value": `+1 transaction,\nReoccurring monthly for the duration of the Premium Plan Device.\nTotal by end of term will be:\n+24 transactions when purchased with iPhone, or Watch.\nOr,\n+36 transactions when purchased with Mac, or iPad.`, "rowSpan": 1, "colSpan": 1 },
      { "value": `1 Month Interest Free with Equal Payment reoccurring monthly for the duration of premium plan.\nYou can only cancel your Apple One Service Plan if you return your Upgrade+ device under Officeworks’ returns policy or if, when eligible, you trade-in and end your plan.​`, "rowSpan": 1, "colSpan": 1 }
    ]
  },
  {
    "cells": [
      { "value": `Each Accessory added (Optional)`, "rowSpan": 1, "colSpan": 1 },
      { "value": "Monthly Payments​", "rowSpan": 1, "colSpan": 1 },
      { "value": `+1 transaction.`, "rowSpan": 1, "colSpan": 1 },
      { "value": `12 Months Interest Free with Equal Monthly Payments.​\nEqual monthly payments required.​`, "rowSpan": 1, "colSpan": 1 }
    ]
  },
  // {
  //   "cells": [
  //     { "value": `Apple Care+ (Optional)`, "rowSpan": 1, "colSpan": 1 },
  //     { "value": "Monthly Payments​", "rowSpan": 1, "colSpan": 1 },
  //     { "value": `+1`, "rowSpan": 1, "colSpan": 1 },
  //     { "value": `24 Months Interest Free with Equal Monthly Payments (iPhone, Watch)​\n36 Months Interest Free with Equal Monthly Payments (Mac, iPad)​\nEqual monthly payments required.​`, "rowSpan": 1, "colSpan": 1 }
  //   ]
  // },
]