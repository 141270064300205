import { useEffect, useState } from "react";
import { useLocation } from "@reach/router";

import { ERROR_CODES } from "../../../components";
import { isBrowser, jwtWatcher } from "../../../utils";
import { QUERY_PARAM } from "../../constants";
import {
  getAuthMode,
  saveMerchantProfile,
  setItem,
  getToken,
  getMerchantProfile,
  setAuthMode,
} from "../utils/storage";
import resetAuth from "../utils/resetAuth";
import fetchMerchantProfileById from "../profile/fetchMerchantProfileById";
import { MODE, TOKEN } from "../constants";

const removeTokenFromQueryString = () => {
  if (!isBrowser()) {
    return;
  }

  const url = new URL(window.location);

  url.searchParams.delete(QUERY_PARAM.TOKEN);
  url.searchParams.delete(QUERY_PARAM.MERCHANT_ID);

  window.history.pushState({}, "", url);
};

const initialState = {
  isLoggedIn: false,
  profile: null,
  fetchingProfile: false,
  error: null,
};

export default function useDefaultAuth() {
  const [state, setState] = useState(initialState);

  const changeState = (newState) =>
    setState({
      ...state,
      ...newState,
    });

  const searchParams = new URLSearchParams(useLocation().search);

  const token = searchParams.get(QUERY_PARAM.TOKEN);
  const merchantId = searchParams.get(QUERY_PARAM.MERCHANT_ID);
  const transactionId = searchParams.get(QUERY_PARAM.TRANSACTION_ID);

  const start = () => {
    resetAuth();
    setItem(TOKEN, token);
    setAuthMode(MODE.DEFAULT);
    removeTokenFromQueryString();
  };

  const loadMerchantProfile = async () => {
    changeState({
      fetchingProfile: true,
    });

    start();

    const {
      success,
      error: profileError,
      profile: merchantProfile,
    } = await fetchMerchantProfileById(merchantId);

    if (!success) {
      changeState({
        error: profileError,
      });
      return;
    }

    // save merchant profile
    saveMerchantProfile(merchantProfile);

    changeState({
      isLoggedIn: true,
      profile: merchantProfile,
    });
  };

  useEffect(() => {
    const handleMerchantToken = () => {
      if (state.fetchingProfile) {
        return;
      }

      if (token && merchantId) {
        loadMerchantProfile();
        return;
      }

      if (getAuthMode() !== MODE.DEFAULT) {
        changeState({
          error: ERROR_CODES.INVALID_REQUEST,
        });
        
        return;
      }

      const merchantToken = getToken();
      const merchantProfile = getMerchantProfile();

      if (!merchantToken) {
        return;
      }

      changeState({
        isLoggedIn: true,
        profile: merchantProfile,
      });

      jwtWatcher.subscribe({
        token: merchantToken,
        onExpiry: () =>
          changeState({
            error: ERROR_CODES.TIME_OUT,
          }),
      });
    };

    handleMerchantToken();

    return () => {
      changeState(initialState);
    };
  }, [token]);

  return {
    loading: state.fetchingProfile,
    error: state.error,
    profile: state.profile,
    isLoggedIn: state.isLoggedIn,
    start,
    params: {
      transactionId,
      merchantId,
      token,
    },
  };
}
