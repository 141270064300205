import React from "react";
import { Stack, Text } from "basis";
import styled from "styled-components";

import { assetsBaseUrl } from "../../../core/config";
import { FormattedPrice, Icon } from "../../../components";

import { CARD_LOGO_ICON_MAP, CARD_LOGO_SRC_MAP } from "./constants";

const StyledImage = styled.img`
  height: 40px;
`;

const AccountImage = ({ productCode }) => {
  const { logo } = CARD_LOGO_SRC_MAP[productCode] || {};
  const { icon } = CARD_LOGO_ICON_MAP[productCode] || {};

  if (logo) {
    return (
      <StyledImage src={`${assetsBaseUrl}/latitude/acceptedCard/${logo}`} />
    );
  }

  if (icon) {
    return <Icon size="64px" type={icon} />;
  }

  return <Icon size="64px" type={CARD_LOGO_ICON_MAP.EMPTY.icon} />;
};

export const AccountItem = ({
  productCode,
  productName,
  availableBalance,
  points,
}) => {
  const availablePoints = parseInt(points, 10) ?? 0;

  return (
    <>
      <Stack gap="1">
        <Text>
          <strong>{productName}</strong>
        </Text>

        <FormattedPrice price={availableBalance} suffix=" available to spend" />

        {availablePoints > 0 && <Text>{availablePoints} points</Text>}
      </Stack>

      <AccountImage productCode={productCode} />
    </>
  );
};
