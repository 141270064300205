import React from "react";
import { Container, Stack, Text } from "basis";
import styled from "styled-components";

import { assetsBaseUrl } from "../../../core/config";
import { Icon } from "../../../components";

import { CARD_LOGO_ICON_MAP, CARD_LOGO_SRC_MAP } from "./constants";

const StyledImage = styled.img`
  height: 42px;
`;

const AccountImage = ({ productCode }) => {
  const { logo } = CARD_LOGO_SRC_MAP[productCode] || {};
  const { icon } = CARD_LOGO_ICON_MAP[productCode] || {};

  if (logo) {
    return (
      <StyledImage src={`${assetsBaseUrl}/latitude/acceptedCard/${logo}`} />
    );
  }

  if (icon) {
    return <Icon size="60px" type={icon} />;
  }

  return <Icon size="60px" type={CARD_LOGO_ICON_MAP.EMPTY.icon} />;
};

export const SelectedPaymentMethod = ({ selectedAccount = {} }) => {
  return (
    <Container bg="grey.t05" padding="4 6">
      <Stack direction="horizontal" gap="4">
        <AccountImage productCode={selectedAccount?.productCode} />

        <Stack gap="1">
          <Text textStyle="body2">
            {selectedAccount?.productName} (**** {selectedAccount?.cardLast4})
          </Text>

          <Text textStyle="body2">{selectedAccount?.cardExpiry}</Text>
        </Stack>
      </Stack>
    </Container>
  );
};
