import React from "react";
import { Container } from "basis";

import { ERROR_CODES } from "../constants";
import { NoLinkedAccounts } from "./NoLinkedAccounts";
import { AccountFetchError } from "./AccountFetchError";
import { AccountNotAvailable } from "./AccountNotAvailable";
import { InsufficientPoints } from "./InsufficientPoints";

export const AccountErrors = ({ minPoints = 0, errors, selectedAccount, orderPoints = 0 }) => {
  let errorCode = null;

  if (errors?.length > 0) {
    const [firstError] = errors;
    errorCode = firstError?.code;
  }else{
    errorCode = selectedAccount?.error?.code
  }

  const showAccountUnavailable =
    selectedAccount && !errorCode && !Boolean(selectedAccount?.isAvailable);

  return (
    <Container margin="0" border>
      {errorCode === ERROR_CODES.ACCOUNT_FETCH_ERROR && <AccountFetchError />}

      {errorCode === ERROR_CODES.NO_ELIGIBLE_ACCOUNTS && <NoLinkedAccounts />}

      {errorCode === ERROR_CODES.INSUFFICIENT_POINTS && (
        <InsufficientPoints minPoints={minPoints} orderPoints={orderPoints}/>
      )}

      {showAccountUnavailable && <AccountNotAvailable />}
    </Container>
  );
};
