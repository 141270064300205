import OktaSignIn from "@okta/okta-signin-widget";
import { navigate } from "@reach/router";

import { OKTA_STATUS } from "../constants";
import { saveOktaTokens } from "./storage";
import { retry } from "../../../utils/retry";
import { logger } from "../../logger";

const ELEMENT_QUERY = {
  MFA_CONTAINER: ".mfa-verify",
  MFA_OPTIONS: "a.factor-option",
  SEND_OTP_BUTTON: '[data-se="sms-send-code"]',
  SEND_OTP_EMAIL: '.button'
};

const MFA_CONTROLLER = {
  VERIFY: "mfa-verify",
};

const IDP_TYPE = {
  SAML: "saml",
};

let authFactorSelected = false;

export const FACTOR_NAME = {
  PASSWORD: "Password",
  SMS: "SMS Authentication",
  EMAIL: "Email Authentication",
};

export const LOGIN_ERROR_TYPE = {
  NO_ALLOWED_FACTORS: "no-allowed-factors",
  MFA_RENDER_FAILED: "mfa-render-failed",
};

export const prepareWidget = ({
  oktaConfig = {},
  mode = "",
  targetElement,
  title = "",
  usernamePlaceholder = "",
  redirectURL,
  customButtons = [],
  allowedFactors = [],
  idpId,
  idpType,
  afterAuth = () => {},
  afterRender = ()=>{},
  onError = () => {},
}) => {
  const {
    issuer,
    clientId,
    redirectUri,
    scopes,
    useInteractionCodeFlow,
    useClassicEngine,
    pkce,
  } = oktaConfig;

  const widget = new OktaSignIn({
    baseUrl: issuer.split("/oauth2")[0],
    clientId,
    redirectUri,
    pkce,
    responseType: "code",
    i18n: {
      en: {
        "primaryauth.title": title,
        "primaryauth.username.placeholder": usernamePlaceholder || "Username",
      },
    },
    authParams: {
      issuer,
      scopes,
    },
    useInteractionCodeFlow,
    useClassicEngine,
    colors: {
      brand: "#006AFF",
    },
    customButtons: customButtons.map((item) => ({
      title: item.title,
      className: "btn-secondary",
      click: item.onClick,
    })),
    features: {
      showPasswordToggleOnSignInPage: true,
      autoFocus: true,
      ...(useClassicEngine ? { passwordlessAuth: true } : {}),
    },
  });

  // do idp login when this parameter is not null
  if (idpId) {
    console.log(`signing in using idp ${idpId}`);

    widget.authClient.token
      .getWithRedirect({ idp: idpId })
      .catch((err) => console.error(`idp login error. ${err}`));

    return widget;
  }

  // do saml login when idpType is saml
  if (idpType === IDP_TYPE.SAML) {
    console.log(`signing in using idp type ${idpType}`);

    widget.authClient.token
      .getWithRedirect()
      .catch((err) => console.error(`saml login error. ${err}`));

    return widget;
  }

  widget.renderEl(
    { el: targetElement },
    async (res) => {
      const { status, tokens } = res;
      if (status !== OKTA_STATUS.SUCCESS) {
        return;
      }

      // save access, id tokens in storage
      const { refreshToken, ...oktaTokens } = tokens;
      saveOktaTokens(oktaTokens, mode);

      // after auth hook
      await afterAuth();

      // send user to redirect URL
      if (redirectURL) {
        navigate(redirectURL);
      }
    },
    (err) => {
      throw err;
    }
  );

  widget.on("afterRender", async (ctx) => {
    let controller = ctx.controller;
    if (allowedFactors?.length > 0 && authFactorSelected) {
      showMFAForm();
    }
    if (allowedFactors?.length > 0 && controller === MFA_CONTROLLER.VERIFY) {
      try {
        await retry({
          fn: () => {
            
            let sendCodeButtonEl = document.querySelector(
              ELEMENT_QUERY.SEND_OTP_BUTTON
            );
         
            // auto send otp for the first time
            if (String(sendCodeButtonEl?.innerText) === "Send code") {
              sendCodeButtonEl.click();
            }

            // let sendMeCodeButtonEl = document.querySelector(
            //   ELEMENT_QUERY.SEND_OTP_EMAIL
            // );
         
            // // auto send otp for the first time
            // if (String(sendMeCodeButtonEl?.value) === "Send me the code") {
            //   sendMeCodeButtonEl.click();
            // }
         
            if (authFactorSelected) {
              return;
            }

            let allFactors = getAllFactors();

            if (allFactors.size < 1) {
              throw new Error("no factors found");
            }

            const params = {
              factors: Array.from(allFactors?.keys()),
              params: Array.from(
                new URLSearchParams(window.location.search).entries()
              ),
            };

            logger.debug(`prepareWidget(): user found with factors`, params);

            for (let i = 0; i < allowedFactors?.length; i++) {
              const currFactor = allowedFactors[i];
              if (allFactors.has(currFactor)) {
                allFactors.get(currFactor).click();
                authFactorSelected = true;
                logger.debug(
                  `prepareWidget(): factor auto selected ${currFactor} `
                );

                break;
              }
            }

            if (authFactorSelected) {
              return;
            }

            onError(
              LOGIN_ERROR_TYPE.NO_ALLOWED_FACTORS,
              new Error("user does not have allowed factors.")
            );
          },
          attempts: 8,
          sleepMsDelay: [100, 100, 100, 300, 300, 500, 500, 700],
        });
      } catch (ex) {
        onError(LOGIN_ERROR_TYPE.MFA_RENDER_FAILED, ex);
      }
    }
  });

  return widget;
};

function showMFAForm() {
  [...document.querySelectorAll(ELEMENT_QUERY.MFA_CONTAINER)].forEach((el) => {
    el.style.visibility = "visible";
  });
}

function getAllFactors() {
  let allFactors = new Map();
  let factorOptionEl =
    document.querySelectorAll(ELEMENT_QUERY.MFA_OPTIONS) || [];

  let factorOptions = [...factorOptionEl];

  // iterate through factors available in the dropdown
  factorOptions.forEach((currEl) => {
    allFactors.set(currEl.innerText?.trim(), currEl);
  });

  return allFactors;
}
