import axiosHelper from "../../../core/axios-helper";
import { ENDPOINTS } from "../../../services/checkout";

export const completePayment = async (
  transactionId,
  promotionCode,
  account,
  points = 0,
  sessionId
) => {
  if (!transactionId) {
    throw new Error(
      "completePayment(): invalid request. transactionID is mandatory"
    );
  }

  const response = await axiosHelper.withCustomerAuth.post(
    ENDPOINTS.PAYMENT_WALLET,
    JSON.stringify({
      transactionId,
      accountId: account?.accountID,
      promotionId: promotionCode,
      points,
      sessionId,
    })
  );

  if (!response?.data?.url) {
    throw new Error(
      "completePayment(): invalid response. response.url mandatory",
      { response }
    );
  }

  return response?.data;
};
