import { useState, useEffect, useMemo, createContext, useContext } from "react";
import { OktaAuth } from "@okta/okta-auth-js";

export default function useSecurity(config) {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");

  if (!config) {
    // throw error if config is null
    throw new Error("config is mandatory");
  }

  let _oktaAuth;
  const initialOktaAuth = useMemo(() => {
    const service = _oktaAuth || new OktaAuth(config);

    return service;
  }, [config, _oktaAuth]);

  const [oktaAuth] = useState(initialOktaAuth);

  const [authState, setAuthState] = useState(
    oktaAuth.authStateManager.getAuthState()
  );

  useEffect(() => {
    async function fetchData() {
      try {
        const unSub = oktaAuth.authStateManager.subscribe((authState) => {
          setAuthState(authState);
        });

        if (oktaAuth.token.isLoginRedirect()) {
          // callback flow
          try {
            await oktaAuth.handleRedirect();
          } catch (err) {
            console.log(`login redirect error ${err}`);
            setError(err);
          }

          setLoading(false);
        } else {
          // Trigger an initial change event to make sure authState is latest when not in loginRedirect state
          oktaAuth.authStateManager.updateAuthState();

          setLoading(false);
        }

        return unSub;
      } catch (err) {
        setLoading(false);
        setError(err.message);
      }
    }

    fetchData();
  }, [oktaAuth]);

  const newContext = createContext({ oktaAuth, authState });
  const result = useContext(newContext);

  return {
    loading,
    error,
    result,
  };
}
