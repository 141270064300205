import React from "react";
import { Message } from "../../../components";

export const AccountNotAvailable = () => (
  <Message
    severity="blocking"
    bg="yellow.t10"
    title="Your account is not available to complete purchase at this moment."
  >
    Please select any other account to proceed.
  </Message>
);
