import { OktaAuth } from "@okta/okta-auth-js";
import getOktaConfig from "./getOktaConfig";

let _oktaAuth;

const getOktaAuth = () => {
  if (!_oktaAuth) {
    const oktaConfig = getOktaConfig();
    _oktaAuth = new OktaAuth(oktaConfig);
  }

  return _oktaAuth;
};

export const getAccessToken = () => getOktaAuth().getAccessToken();
export const getIdToken = () => getOktaAuth().getIdToken();

export const clearTokenManager = () => getOktaAuth().tokenManager.clear();