import { ENDPOINTS } from "../../../services/checkout";
import axiosHelper from "../../axios-helper";
import { logger } from "../../logger";
import { ERROR_TYPES, USER_CLAIM } from "../constants";


const fetchLinkedProfiles = async (
  maxRows= 50,
  nextToken = "",
  searchText = "") => {
  try {
    let baseParams
    if (maxRows === undefined) {
      baseParams = {}
    } else {
      baseParams = {
        nextToken,
        maxItems: Number(maxRows),
        merchantID: searchText
      };
    }

    const response = await axiosHelper.withMerchantAuth.get(
      ENDPOINTS.ACCOUNT_PROFILES,
      {
        params: {
          ...baseParams
        },
      }
    );

    const userGivenName = response?.data?.userGivenName || "";
    const firstName = response?.data?.firstName || "";
    const lastName = response?.data?.lastName || "";
    const userEmail = response?.data?.userEmail || "";
    const isTrainingRequired = Boolean(response?.data?.isTrainingRequired);
    const profiles = response?.data?.profiles || [];

    const user = {
      userEmail,
      userGivenName,
      firstName,
      lastName,
      isTrainingRequired,
    };

    return {
      success: true,
      profiles: transformProfiles(user, profiles),
      nextToken: response?.data?.nextToken
    };
  } catch (ex) {
    const error = {
      type: ERROR_TYPES.PROFILE_FETCH_FAILED,
      message: ex.message,
    };

    return { success: false, error };
  }
};

const transformProfiles = (user, profiles) => {
  try {
    const transformedProfiles = profiles?.map((profile) => {
      const userClaims = profile?.userClaims || [];

      const hasOrderUpdateAccess = userClaims.includes(
        USER_CLAIM.ORDERS_UPDATE
      );
      const hasOrderCreateAccess = userClaims.includes(
        USER_CLAIM.ORDERS_CREATE
      );
      const hasOrderReadAccess = userClaims.includes(
        USER_CLAIM.ORDERS_READ
      );
      const hasAnalyticsReadAccess = userClaims.includes(
        USER_CLAIM.ANALYTICS_READ
      );
      const hasSLAReadAccess = userClaims.includes(
        USER_CLAIM.SLA_READ
      );
      const hasStatementReadAccess = userClaims.includes(
        USER_CLAIM.STATEMENTS_READ
      );

      return {
        ...profile,
        ...user,
        features: {
          ...profile?.features,
          isOrderUpdateDisabled: !hasOrderUpdateAccess,
          isOrderCreateEnabled: hasOrderCreateAccess,
          isOrderReadEnabled: hasOrderReadAccess,
          isAnalyticsEnabled: hasAnalyticsReadAccess,
          isSLAEnabled: hasSLAReadAccess,
          isStatementEnabled: hasStatementReadAccess,
        },
      };
    });

    return transformedProfiles;
  } catch (ex) {
    logger.error("transformProfiles(): failed to transform profiles", ex);

    return profiles;
  }
};

export default fetchLinkedProfiles;
