import React from "react";
import { Message } from "../../../components";

export const InsufficientPoints = ({ minPoints = 5000, orderPoints=0 }) => {
  let message = `Unfortunately, we could not retrieve the points balance. 
  You can continue with available card balance or return to cart to select 
  an alternative payment method.`;

  if (minPoints > 0){
    message = `Unfortunately, you do not have the ${minPoints} minimum points required per transaction.
     Please return to cart and select an alternative payment method`;
  }
  //points required to complete the transaction is less than min points
  if (orderPoints < minPoints){
    message = `Unfortunately, this transaction does not qualify to pay with points. A minimum of ${minPoints} pts must be used.
     Please return to cart and select an alternative payment method.`
  }

  return (
    <Message
      severity="warning-or-significant"
      bg="secondary.pink.t30"
      title="Insufficient points"
    >
      {message}
    </Message>
  );
};
