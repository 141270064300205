import React from "react";

import { LoadingMessage } from "../../../components";

import useCustomerAuth from "../hooks/useCustomerAuth";
import useDefaultAuth from "../hooks/useDefaultAuth";
import useMerchantAuth from "../hooks/useMerchantAuth";

import { MODE } from "../constants";

const ProtectedDefaultRoute = ({
  as: Component,
  location,
  mode,
  ErrorComponent,
  ...rest
}) => {
  if (!ErrorComponent) {
    throw new Error("ErrorComponent is mandatory for default mode");
  }

  const { error, isLoggedIn } = useDefaultAuth();

  if (isLoggedIn) {
    return <Component {...rest} />;
  }

  if (error) {
    return <ErrorComponent {...rest} />;
  }

  return (
    <LoadingMessage message="Please wait while we process your request.." />
  );
};

const ProtectedMerchantRoute = ({
  as: Component,
  location,
  mode,
  SignInComponent,
  isDisabled,
  ...rest
}) => {
  if (!SignInComponent) {
    throw new Error("SignInComponent is mandatory for merchant mode");
  }

  const { isLoggedIn } = useMerchantAuth();
  if (isLoggedIn() && !isDisabled) {
    return <Component {...rest} />;
  }

  return <SignInComponent {...rest} />;
};

const ProtectedCustomerRoute = ({
  as: Component,
  location,
  mode,
  SignInComponent,
  ...rest
}) => {
  if (!SignInComponent) {
    throw new Error("SignInComponent is mandatory for customer mode");
  }

  const { isLoggedIn } = useCustomerAuth();
  if (isLoggedIn()) {
    return <Component {...rest} />;
  }

  return <SignInComponent {...rest} />;
};

const ProtectedRoute = (props) => {
  switch (props?.mode) {
    case MODE.DEFAULT:
      return <ProtectedDefaultRoute {...props} />;

    case MODE.MERCHANT:
      return <ProtectedMerchantRoute {...props} />;

    case MODE.CUSTOMER:
      return <ProtectedCustomerRoute {...props} />;

    default:
      return <LoadingMessage />;
  }
};

ProtectedRoute.MODE = MODE;

export default ProtectedRoute;
