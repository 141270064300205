import { logger } from "../core/logger";

// Retry a function with attempts and sleep
export const retry = async ({
  fn = () => {},
  onRetryBegin = () => {},
  attempts = 5,
  sleepMs = 1000,
  sleepMsDelay = [300, 800, 1500, 1700, 2000],
}) => {
  let lastError = {};

  for (let i = 0; i < attempts; i++) {
    try {
      onRetryBegin(i);
      console.info("retry: " + Number(sleepMsDelay[i]));
      return await fn();
    } catch (e) {
      lastError = e;
      await sleep(Number(sleepMsDelay[i]) || sleepMs);
    }
  }

  throw new Error(
    `Failed after ${attempts} attempts, last error: [${lastError}]`
  );
};

export const sleep = (ms = 1000) =>
  new Promise((resolve) => setTimeout(resolve, ms));

export const poll = async function(fn, fnCondition, ms) {
  try {
    let result = await fn();
    while (fnCondition(result)) {
      await sleep(ms);
      result = await fn();
    }
    return result;
  } catch (ex) {
    logger.error(ex);
  }
};
