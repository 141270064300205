import { customerOktaConfig } from "../../config";

import { MODE } from "../constants";
import useSecurity from "./useSecurity";
import { getAuthMode, setAuthMode, getToken } from "../utils/storage";
import resetAuth from "../utils/resetAuth";
import { isJWTValid } from "../utils/isJWTValid";

export default function useCustomerAuth() {
  const { loading, error, result } = useSecurity(customerOktaConfig);
  const { authState, oktaAuth } = result;

  const isLoggedIn = () => {
    if (getAuthMode() !== MODE.CUSTOMER) {
      return false;
    }

    const token = getToken();
    return isJWTValid(token);
  };

  const start = () => {
    resetAuth();
    setAuthMode(MODE.CUSTOMER);
  };

  const logout = async() => {
    resetAuth();
    await oktaAuth.signOut({
      clearTokensBeforeRedirect: true,
    })
   

    window.location.reload(false);
  };

  return {
    loading,
    error,
    authState,
    oktaAuth,
    isLoggedIn,
    start,
    logout,
  };
}
