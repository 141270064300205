import React, { useState, useEffect } from "react";
import { Message, Link } from "basis";

import { Container } from "../../../components";
import { APP_MESSAGE_BG, APP_MESSAGE_TYPE } from "./constants";
import Banner from "../../../core/Banner";

export const AppMessage = ({ area = "" }) => {  
  const [appMessages, setMessage] = useState({});
  const banner = new Banner();

  useEffect(() => {
    banner.getConfig().then(config => {
        if (config[area])
          setMessage(config[area]);
    })
  }, [appMessages.isEnabled])

  const { 
    isEnabled, 
    type, 
    title, 
    description, 
    contactEmail 
  } = appMessages;

  if (!isEnabled) {
    return null;
  }

  const bg =
    APP_MESSAGE_BG[type] || APP_MESSAGE_BG[APP_MESSAGE_TYPE.NOTIFICATION];

  return (
    <Container padding="6 0 0 0" maxWidth="1110" bg="transparent">
      <Message severity="info-or-minor" bg={bg} title={title}>
        {contactEmail && (
          <>
            <Link href={`mailto:${contactEmail}`} newTab>
              Contact us
            </Link>{" "}
          </>
        )}
        {description}
      </Message>
    </Container>
  );
};
