export default function validateBannerConfig(config) {
    if (!config || typeof config !== "object"){
        throw new Error("Invalid config format in banner.json file.");
    }

    const areas = Object.keys(config);

    areas.forEach(area => {
        //check structure
        if (
            !config[area].hasOwnProperty("isEnabled") ||
            !config[area].hasOwnProperty("type") ||
            !config[area].hasOwnProperty("title") ||
            !config[area].hasOwnProperty("description") ||
            !config[area].hasOwnProperty("contactEmail")
        ){
            throw new Error("Invalid config structure in banner.json file.");
        }

        //check data types
        if (
            typeof config[area].isEnabled !== 'boolean' ||
            typeof config[area].type !== 'string' ||
            typeof config[area].title !== 'string' ||
            typeof config[area].description !== 'string'
        ){
            throw new Error("Invalid config data type in banner.json file.");
        }
    })
}
  