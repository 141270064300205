import React from "react";
import styled from "styled-components";
import { Button, Link, Text } from "basis";
import { BUTTON_TEXT } from "..";
import { COUNTRY } from "../../../core/constants";

const Wrapper = styled.div`
  margin: 24px 0;

  button {
    cursor: pointer;
    width: 100%;
  }
`;

export const CompletePayment = ({
  disabled,
  isCompletingPayment,
  isAccountSelected,
  allowWalletPayment,
  onPaymentClick = () => { },
  onManualPaymentClick = () => { },
  country,
  urlTerms
}) => {
  const handlePaymentClick = () => {
    if (disabled || !isAccountSelected || isCompletingPayment) {
      return;
    }

    onPaymentClick();
  };

  if (!allowWalletPayment) {
    return (
      <Wrapper>
        <Button
          variant="secondary"
          loading={isCompletingPayment}
          onClick={onManualPaymentClick}
        >
          {BUTTON_TEXT.MANUAL_PAYMENT}
        </Button>
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <Text textStyle="legal" color="grey.t75" margin="8 0 4">
        By clicking 'Complete payment' you agree that you have read, understand
        and accept our{" "}
        <Link
          href={urlTerms ?? `https://www.latitudefinancial.com.au/forms/`}
          newTab
        >
          terms and conditions
        </Link>{" "}
        and{" "}
        <Link
          href={
            country === COUNTRY.NZ
              ? `https://www.gemfinance.co.nz/privacy`
              : `https://www.latitudefinancial.com.au/privacy`
          }
          newTab
        >
          privacy policy
        </Link>
        .
      </Text>

      <Button
        loading={isCompletingPayment}
        disabled={disabled || !isAccountSelected}
        onClick={handlePaymentClick}
      >
        Complete payment
      </Button>
    </Wrapper>
  );
};
